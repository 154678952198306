export const environment = {
  production: false,
  title: 'Production Environment',
  apiURL: 'https://demoapi.omniassist.co.in',
  // b2bApiUrl: 'https://demoapi.omniassist.co.in/api/v1/B2B/admin',
  // b2bApiUrl2: 'https://demoapi.omniassist.co.in/api/v1/B2B',
  // salesApiURL: 'https://demoapi.omniassist.co.in/api/v1/Sales/Admin',
  // imageUrl: 'https://demoapi.omniassist.co.in/images',
  // blogImageUrl: 'https://demoapi.omniassist.co.in/blog',
  // bannerImageUrl: 'https://demoapi.omniassist.co.in/banner',
  // thumbNailUrl: 'https://demoapi.omniassist.co.in/thumbnail',
  // siteUrl: 'https://www.portal.omniassist.co.in',
};