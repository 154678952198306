import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { LoginResponse } from '../model/login.model';
import { Data } from '../model/data.model';
import { UserDetails } from '../model/user-details.model';
import { Cart, CartItem, CartTotal } from '../model/cart.model';
import { OrderPage, Page } from '../model/page.model';
import { List } from '../model/list.model';
import { OrderDetails, OrdersList } from '../model/orders.model';
import { ContactUs } from '../model/contactus.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  signUp(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/User/AddCustomerInformation`, data).pipe(
      catchError(this.handleError)
    )
  }

  signIn(data: any): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.apiURL}/User/SignUpOrLogin`, data).pipe(
      catchError(this.handleError)
    )
  }

  sendEmailOtp(email: any): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/SendEmailOtp`, email).pipe(
      catchError(this.handleError)
    )
  }

  checkEmailSendOtp(email: any): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/CheckUserEmailOtp`, email).pipe(
      catchError(this.handleError)
    )
  }

  checkPhoneSendOtp(phone: any): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/CheckUserPhoneOtp`, phone).pipe(
      catchError(this.handleError)
    )
  }

  sendPhoneOtp(phone: any): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/SendPhoneOtp`, phone).pipe(
      catchError(this.handleError)
    )
  }
  updateUserPhoneOtp(phone: any): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/UpdateUserPhoneOtp`, phone)
  }

  updateUserPhoneOtpNew(phone: any): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/UpdateUserPhoneOtpNew`, phone);
  }

  updateUserEmailPhoneOtp(phone: any): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/UpdateUserEmailPhoneOtp`, phone);
  }

  getUserDetails(): Observable<Data<UserDetails[]>> {
    return this.http.get<Data<UserDetails[]>>(`${environment.apiURL}/User/GetUserDetails`).pipe(
      catchError(this.handleError)
    )
  }

  logout(): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/LogOut`, null).pipe(
      catchError(this.handleError)
    )
  }

  updateUserName(obj: any): Observable<Data<any>> {
    return this.http.put<Data<any>>(`${environment.apiURL}/User/UpdateUserName`, obj).pipe(
      catchError(this.handleError)
    )
  }

  updateEmail(obj: any): Observable<Data<any>> {
    return this.http.put<Data<any>>(`${environment.apiURL}/User/UpdateEmail`, obj).pipe(
      catchError(this.handleError)
    )
  }

  updatePhone(obj: any): Observable<Data<any>> {
    return this.http.put<Data<any>>(`${environment.apiURL}/User/UpdatePhone`, obj).pipe(
      catchError(this.handleError)
    )
  }

  addProductUserCart(data: CartItem): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/Cart/AddToCart`, data).pipe(
      catchError(this.handleError)
    )
  }

  getUserCart(data: Page): Observable<Data<List<Cart>>> {
    return this.http.post<any>(`${environment.apiURL}/Cart/UserCartList`, data).pipe(
      catchError(this.handleError)
    )
  }

  deleteProductUserCart(data: CartItem): Observable<Data<any>> {
    return this.http.delete<Data<any>>(`${environment.apiURL}/Cart/RemoveFromCart`, { body: data }).pipe(
      catchError(this.handleError)
    )
  }

  getUserCartCalculation(): Observable<Data<CartTotal[]>> {
    return this.http.post<any>(`${environment.apiURL}/Cart/GetCartTotalUser`, null).pipe(
      catchError(this.handleError)
    )
  }

  getUserOrders(page: OrderPage): Observable<Data<List<OrdersList>>> {
    return this.http.post<Data<List<OrdersList>>>(`${environment.apiURL}/Order/userOrdes`, page).pipe(
      catchError(this.handleError)
    )
  }

  getUserOrderDetails(obj: any): Observable<Data<OrderDetails[]>> {
    return this.http.post<Data<OrderDetails[]>>(`${environment.apiURL}/Order/UserorderDetails`, obj).pipe(
      catchError(this.handleError)
    )
  }

  placeOrder(obj: any): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/Order/placeOrder`, obj).pipe(
      catchError(this.handleError)
    )
  }

  deactivateUser(): Observable<Data<any>> {
    return this.http.post<Data<any>>(`${environment.apiURL}/User/DeactivateMyAccount`, null).pipe(
      catchError(this.handleError)
    )
  }
  contactUs(obj: ContactUs): Observable<Data<any>> {
    return this.http.post<Data<ContactUs>>(`${environment.apiURL}/ContactUs/ContactUs`, obj).pipe(
      catchError(this.handleError)
    )
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    }
    else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;

    }
    return throwError(() => errorMessage);
  }

  paymentWithOrderId(orderId: number) {
    return this.http.post<any>(`${environment.apiURL}/payment/PaymentWithOrderId`, { OrderId: orderId });
  }
}

